
import { ListDataSource } from "@/data/List/ListDataSource";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ModeratorSelector extends Vue {
  @Prop() sessionProfessionalArea!: any;
  @Prop() professionalAreaDataSource!: ListDataSource;
  @Prop({ default: "Не определено" }) notDefinedCaption!: string;
  @Prop() canEdit!: string;
  professionalAreaId: any = null;
  editMode: boolean = false;

  get professionalArea() {
    return this.professionalAreaDataSource.items.find(
      (i: any) => i.id === this.dataSource.model.professionalAreaId,
    );
  }

  dataSource: ObjectDataSource = new ObjectDataSource({
    className: "EventSessionProfessionalArea",
    id: this.sessionProfessionalArea.id,
    model: this.sessionProfessionalArea,
    config: {},
  });

  async update(professionalAreaId) {
    await this.dataSource.updateField({
      fieldName: "professionalAreaId",
      fieldValue: professionalAreaId,
    });
    this.editMode = false;
  }
}
