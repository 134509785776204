
import { ListDataSource } from "@/data/List/ListDataSource";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import locations from "./locations.vue";
import draggable from "vuedraggable";

import metadata from "./metaData.json";
import { sortByPriority, sortByPriorityDesc } from "@/cms-services/consts";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
@Component({
  components: { locations, draggable },
})
export default class ScheduleEditor extends Vue {
  @Prop() schedule!: any;
  @Prop() editMode!: boolean;
  @Prop() eventId!: number;
  @Prop() activatable!: boolean;
  @Prop({ default: null }) braodcastId!: number;
  moment = moment;
  currentDay = null;
  scheduleObjectDataSource: ObjectDataSource = new ObjectDataSource({
    className: "EventSchedule",
    model: this.schedule,
    id: this.schedule.id,
    config: {},
  });
  dataSource: ListDataSource = new ListDataSource({
    className: "EventDay",
    config: {
      pageIndex: 1,
      pageSize: 200,
      orderFieldName: "Priority",
      orderFieldDirection: "ASC",
    },
  });
  personDataSource: ListDataSource = new ListDataSource({
    className: "Person",
    config: {
      pageIndex: 1,
      pageSize: 200,
    },
  });
  professionalAreaDataSource: ListDataSource = new ListDataSource({
    className: "ProfessionalArea",
    config: {
      pageIndex: 1,
      pageSize: 200,
    },
  });

  async changeActiveDay(day: any, event: boolean) {
    await this.scheduleObjectDataSource.updateField({
      fieldName: "activeDayId",
      fieldValue: event ? day.id : null,
    });
  }

  loaded: boolean = false;
  $message: any;

  canShow(day) {
    if (!this.braodcastId) return true;
    return day.eventDayLocations.some(
      (dl: any) => dl.eventBroadcastId == +this.braodcastId,
    );
  }

  get sortedDays() {
    return this.dataSource.items.sort(sortByPriorityDesc);
  }

  getFormatedDay(day: any) {
    const instance = moment(day);
    if (!instance.isValid()) return "Не определено";
    return instance.format("DD.MM.YYYY");
  }

  async created() {
    this.dataSource.items = this.schedule.eventDays;
    this.dataSource.metadata = { properties: metadata.day };
    await this.personDataSource.get();
    await this.professionalAreaDataSource.get();
    this.loaded = true;
  }

  async add() {
    await this.dataSource.add({
      eventId: +this.eventId,
      eventScheduleId: this.schedule.id,
      caption: "Новый день" + this.dataSource.items.length,
      priority: this.dataSource.items.length,
    });
  }

  async remove(item: any) {
    const hasLocations = item.eventDayLocations.length > 0;
    if (hasLocations) {
      return this.$message(
        "Чтобы удалить локцию удалите все дочерние локации",
        "error",
      );
    }
    await this.dataSource.remove(item.id);
  }
}
