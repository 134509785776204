
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import metaData from "./metaData.json";
import draggable from "vuedraggable";
import SessionItemPerson from "./session-item-persons.vue";
import SessionItemProfessionalArea from "./session-item-professional-area.vue";
import timePeriodClass from "./timePeriod";
import ItemLayout from "./item-layout.vue";
import { instaceStates } from "@/cms-services/consts";
@Component({
  components: {
    draggable,
    SessionItemPerson,
    SessionItemProfessionalArea,
    ItemLayout,
  },
})
export default class SessionItems extends Vue {
  @Prop() session!: any;
  @Prop() personDataSource!: ListDataSource;
  @Prop() professionalAreaDataSource!: ListDataSource;
  @Prop() isOpen!: boolean;
  @Prop() eventId!: number;
  @Prop() scheduleId!: number;
  @Prop() editMode!: boolean;
  @Prop() activatable!: boolean;

  dataSource: ListDataSource = new ListDataSource({
    className: "EventSessionItem",
    config: {},
  });

  get sortedItems() {
    return this.dataSource.items.sort(
      (p: any, n: any) => p.priority - n.priority,
    );
  }

  changeActive(sessionItem: any, event: boolean) {
    this.$emit("change-active-session-item", {
      sessionItemId: sessionItem.id,
      value: event,
    });
  }

  isCorrectDuration = (session, eventSessionIndex) => {
    const res = timePeriodClass.isCorrectDuration(session, eventSessionIndex);
    return res;
  };
  $message: any;

  getInstanceStateColor(session: any) {
    const src = {
      1: "secondary",
      4: "success",
    };

    return src[session.instanceStateId];
  }
  getInstanceStateCaption(session: any) {
    return instaceStates[session.instanceStateId];
  }
  getOnlineColor(session: any) {
    const src = {
      0: "primary",
      1: "success",
    };
    return src[session.participationTypeId];
  }
  getOnlineCaption(session: any) {
    const src = {
      0: "Оффлайн",
      1: "Онлайн",
    };
    return src[session.participationTypeId];
  }

  getDataSource(id: number) {
    return this.dataSource.getObjectDataSource(id);
  }

  getMetaData(name: string) {
    return this.dataSource.getMetaDataByName(name);
  }

  async created() {
    this.dataSource.metadata = { properties: metaData.sessionItem };
    this.dataSource.items = this.session.eventSessionItems;
  }

  async add() {
    await this.dataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: this.scheduleId,
        eventSessionId: this.session.id,
        caption: "Новая лекция " + this.dataSource.items.length,
        duration: 30,
      },
      true,
    );

    this.$message("Успешно добавлено");
  }

  async remove(sessionItem: any) {
    const hasPersons = sessionItem.eventSessionItemPersons.length > 0;
    const hasProfessionalArea =
      sessionItem.eventSessionItemProfessionalAreas.length > 0;
    if (hasPersons || hasProfessionalArea) {
      return this.$message(
        "Чтобы удалить лекцию удалите все дочерние элементы",
        "error",
      );
    }
    await this.dataSource.remove(sessionItem.id);
  }
}
