
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import SessionItems from "./session-items.vue";
import metaData from "./metaData.json";
import draggable from "vuedraggable";
import SessionModerators from "./session-moderators.vue";
import SessionProfessionalArea from "./session-professional-area.vue";
import TimePeriod from "./timePeriod";
import { instaceStates } from "@/cms-services/consts";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import ItemLayout from "./item-layout.vue";
@Component({
  components: {
    draggable,
    SessionItems,
    SessionModerators,
    ItemLayout,
    SessionProfessionalArea,
  },
})
export default class EventSessions extends Vue {
  @Prop() dayLocation!: any;
  @Prop() activeDayId!: number;
  @Prop() isOpen!: boolean;
  @Prop() editMode!: boolean;
  @Prop() eventId!: number;
  @Prop() scheduleId!: number;
  @Prop() personDataSource!: ListDataSource;
  @Prop() professionalAreaDataSource!: ListDataSource;
  @Prop() activatable!: boolean;

  loaded: boolean = false;
  dataSource: ListDataSource = new ListDataSource({
    className: "EventDayLocationSession",
    config: {
      pageIndex: 1,
      pageSize: 200,
    },
  });

  async changeActive(sessionId: number, value: boolean) {
    await this.resetActiveSessionItems();
    this.$emit("change-active-session", {
      sessionId,
      value,
    });
  }

  async resetActiveSessionItems() {
    for (const session of this.sessionDataSource.items) {
      if (session.activeSessionItemId == null) continue;
      await this.sessionDataSource.updateField(session.id, [
        {
          fieldName: "activeSessionItemId",
          fieldValue: null,
        },
      ]);
    }
  }

  async changeSessionItemActive(sessionId: number, event: any) {
    await this.resetActiveSessionItems();
    await this.sessionDataSource.updateField(sessionId, [
      {
        fieldName: "activeSessionItemId",
        fieldValue: event.value ? event.sessionItemId : null,
      },
    ]);

    this.$emit("change-active-session", {
      sessionId,
      value: event.value,
    });
  }

  getInstanceStateColor(session: any) {
    const src = {
      1: "secondary",
      4: "success",
    };

    return src[session.instanceStateId];
  }
  getInstanceStateCaption(session: any) {
    return instaceStates[session.instanceStateId];
  }
  getOnlineColor(session: any) {
    const src = {
      0: "primary",
      1: "success",
    };
    return src[session.participationTypeId];
  }
  getOnlineCaption(session: any) {
    const src = {
      0: "Оффлайн",
      1: "Онлайн",
    };
    return src[session.participationTypeId];
  }
  getSessionTypeColor(session: any) {
    const src = {
      0: "primary",
      1: "success",
      2: "secondary",
    };
    return src[session.sessionTypeId];
  }
  getSessionTypeCaption(session: any) {
    const src = {
      0: "Обычная",
      1: "Мультисессия",
      2: "Свободное время",
    };
    return src[session.sessionTypeId];
  }

  getSessionShortInfo(session: any) {
    let shortInfo = `Количество лекций ${
      session.eventSessionItems.length
    }, ${instaceStates[session.instanceStateId].toLowerCase()}, `;
    const sessionTypes = ["обычная", "мультисессия", "свободная время"];
    shortInfo += sessionTypes[session.sessionTypeId] + " ";

    return shortInfo;
  }

  getTimePeriodState = (index: number) => {
    return TimePeriod.getTimePeriodState(this.sessionDataSource.items, index);
  };

  $message: any;

  getDataSource(id: number) {
    return this.sessionDataSource.getObjectDataSource(id);
  }

  getMetaData(name: string) {
    return this.sessionDataSource.getMetaDataByName(name);
  }

  sessionDataSource: ListDataSource = new ListDataSource({
    className: "EventSession",
    config: {},
  });

  async created() {
    this.sessionDataSource.metadata = { properties: metaData.session };
    this.dataSource.items = this.dayLocation.eventLocationSessions;
    this.sessionDataSource.items = this.dayLocation.eventLocationSessions.map(
      (e: any) => e.eventSession
    );
  }

  async fixTime(index: number) {
    const fixedTimePeriod = TimePeriod.fixTimePeriod(
      this.sessionDataSource.items,
      index
    );

    await this.sessionDataSource.updateField(
      this.sessionDataSource.items[index].id,
      [
        {
          fieldName: "timePeriod",
          fieldValue: fixedTimePeriod,
        },
      ]
    );

    await this.fixLinkedTimePerods(index + 1);
  }

  async fixLinkedTimePerods(startIndex) {
    const sessions = this.sessionDataSource.items;
    for (let i = startIndex; i < sessions.length; i++) {
      const session = sessions[i];
      if (session.linkTypeId == 0) await this.fixTime(i);
    }
  }

  async add(addToEnd: boolean = true, index = null) {
    const length = this.sessionDataSource.items.length;
    let i = index !== null ? index : length == 0 ? 0 : length - 1;
    let idx = (addToEnd ? i : (i as number) - 1) as number;

    const time =
      idx > -1
        ? this.sessionDataSource.items?.[idx]?.timePeriod
        : "08:00-09:00";

    const session = await this.sessionDataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: +this.scheduleId,
        eventDayId: +this.dayLocation.eventDayId,
        caption: "Новая сессия" + this.sessionDataSource.items.length,
        timePeriod: TimePeriod.getNewTimePeriod(time ?? "08:00-09:00"),
      },
      addToEnd
    );

    await this.dataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: +this.scheduleId,
        eventDayId: +this.dayLocation.eventDayId,
        eventDayLocationId: this.dayLocation.id,
        eventSessionId: session.id,
      },
      addToEnd
    );
    this.$message("Успешно добавлено");
  }

  async remove(eventSession: any) {
    const session = this.dataSource.items.find(
      (e: any) => e.eventSessionId == eventSession.id
    );
    if (session === null) return;
    const hasItems = eventSession.eventSessionItems.length > 0;
    const hasProfessionalArea =
      eventSession.eventSessionProfessionalAreas.length > 0;
    const hasModerators = eventSession.eventSessionModerators.length > 0;
    if (hasItems || hasProfessionalArea || hasModerators) {
      return this.$message(
        "Чтобы удалить сессию удалите все дочерние элементы",
        "error"
      );
    }
    await this.dataSource.remove(session.id);
    await this.sessionDataSource.remove(eventSession.id);
    this.$message("Успешно удалено");
  }
}
