export default class TimePeriod {
  /**
   * Получить длительность текущего timePeriod а в минутах
   *
   * @param timePeriod текуший timePeriod
   * @returns длительность текущего timePeriod а в минутах
   */
  static getTimePeriodDurationInMinutes = (timePeriod: string) => {
    const _allTimes = timePeriod.split("-");
    const _startTime =
      (Number(_allTimes[1].split(":")[0]) -
        Number(_allTimes[0].split(":")[0])) *
      60;
    const _endTime =
      Number(_allTimes[1].split(":")[1]) - Number(_allTimes[0].split(":")[1]);
    return _endTime + _startTime;
  };

  /**
   * Получить корректость текущего timePeriod а
   *
   * @param sessions текуший массив всех сессий
   * @param index индекс текущей сессии
   * @returns корректность текущего timePeriod а
   */
  static getTimePeriodState(sessions: Array<any>, index: number): string {
    const _currentTimePeriodDuration = this.getTimePeriodDurationInMinutes(
      sessions[index].timePeriod,
    );
    if (_currentTimePeriodDuration < 0)
      return "Конец не может быть раньше чем начало";
    const _timePeriodsDifference =
      index > 0
        ? this.getTimePeriodsDifference(
            sessions[index - 1].timePeriod,
            sessions[index].timePeriod,
          )
        : 0;
    if (_timePeriodsDifference < 0)
      return "Сессия не может начаться прежде чем закончится старая сессия";

    const _eventSessionDuration =
      sessions[index].sessionTypeId === 0
        ? sessions[index].eventSessionItems.reduce(
            (sum, current) => (sum += current.duration),
            0,
          )
        : Math.max(...sessions[index].eventSessionItems.map((x) => x.duration));

    return _currentTimePeriodDuration < _eventSessionDuration
      ? `Не хватает ${_eventSessionDuration - _currentTimePeriodDuration} минут`
      : "";
  }

  /**
   * Получить корректность текущего timePeriod а относительно предедущей
   *
   * @param prevTimePeriod текущий timePeriod
   * @param currentTimePeriod предедущий timePeriod
   * @returns корректность текущей относительно предедущей
   */
  static getTimePeriodsDifference(
    prevTimePeriod: string,
    currentTimePeriod: string,
  ): number {
    const _prevTimeEnd = this.getTimeInMinutes(prevTimePeriod.split("-")[1]);
    const _currentTimeStart = this.getTimeInMinutes(
      currentTimePeriod.split("-")[0],
    );

    return _currentTimeStart - _prevTimeEnd;
  }

  /**
   * Получить заданное время в минутах
   *
   * @param time время (тип строка)
   * @returns заданное время в минутах
   * "08:00" --> 480
   */
  static getTimeInMinutes = (time: string) =>
    Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]);

  /**
   * Получить новый timePeriod на durationInMinutes больше
   *
   * @param timePeriod текущий timePeriod(тип строка)
   * @param durationInMinutes Длительность в минутах(тип Number)
   * @returns новый timePeriod на durationInMinutes больше
   * "08:00-09:00", 30 --> "09:00-09:30"
   */
  static getNewTimePeriod(
    timePeriod: string,
    durationInMinutes: number = 30,
  ): string {
    if (!timePeriod) return "08:00-08:30";
    const _oldEndHour = timePeriod.split("-")[1].split(":")[0];
    const _oldEndMinute = timePeriod.split("-")[1].split(":")[1];
    const _newStartTime = this.getConcatTime(_oldEndHour, _oldEndMinute, ":");
    const _newEndTime = this.getNewTime(_newStartTime, durationInMinutes);

    return this.getConcatTime(_newStartTime, _newEndTime, "-");
  }

  /**
   * В конец текущего TimePeriod прибавить durationInMinutes
   *
   * @param timePeriod текущий timePeriod(тип строка)
   * @param durationInMinutes Длительность в минутах(тип Number)
   * @returns новый timePeriod на durationInMinutes больше
   * "08:00-09:00", 30 --> "08:00-08:30"
   */
  static incTimePeriod(timePeriod: string, durationInMinutes: number = 30) {
    const _startTime = timePeriod.split("-")[0];
    const _endTime =
      durationInMinutes > 0
        ? this.getNewTime(_startTime, durationInMinutes)
        : this.getNewTime(_startTime, 30);
    return this.getConcatTime(_startTime, _endTime, "-");
  }

  /**
   * Получить новый time на durationInMinutes больше
   *
   * @param time время (тип строка)
   * @param durationInMinutes Длительность в минутах (тип Number)
   * @returns новый time на durationInMinutes больше
   * "08:00", 30 --> "08:30"
   */
  static getNewTime(time: string, durationInMinutes: number): string {
    let _hour = Number(time.split(":")[0]);
    let _minute = Number(time.split(":")[1]);
    const _sum = Number(_minute) + durationInMinutes;
    _minute = _sum % 60;
    _hour = Number(_hour) + (_sum - (_sum % 60)) / 60;

    return this.getConcatTime(
      this.numPadding(_hour),
      this.numPadding(_minute),
      ":",
    );
  }

  /**
   * @param startTime время начала (тип строка)
   * @param endTime времы конец (тип строка)
   * @param devider разделитель (тип строка)
   * @returns startTime + devider + endTime
   * "08:00", "09:00", "-"  --> "08:30:00"
   * "08" , "00" , ":" --> "08:00"
   */
  static getConcatTime = (
    startTime: string,
    endTime: string,
    devider: string,
  ) => startTime + devider + endTime;

  /**
   * @param num число (тип Number)
   * @returns если чило < 10 то прибавить в начало 0
   * 9 -> "09"
   */
  static numPadding = (num: number) =>
    num < 10 ? `0${String(num)}` : String(num);

  /**
   * Проверка вмещается ли данная лекция в timePeriod
   *
   * @param session текущяя сессия из нее возьмём { timePeriod, eventSessionItems, sessionTypeId }
   * @param eventSessionIndex индекс текущей лекции
   * @returns boolean вмещяется ли данная лекция в timePeriod
   */
  static isCorrectDuration(
    { timePeriod, eventSessionItems, sessionTypeId },
    eventSessionIndex: number,
  ): boolean {
    const _timePeriodDuration = this.getTimePeriodDurationInMinutes(timePeriod);

    let _durationSum = 0;
    if (sessionTypeId === 0) {
      for (let index = 0; index <= eventSessionIndex; index++) {
        _durationSum += Number(eventSessionItems[index].duration);
      }
    } else {
      _durationSum = eventSessionItems[eventSessionIndex].duration;
    }

    return _timePeriodDuration - _durationSum >= 0;
  }

  /**
   * Исправление timePeriod
   *
   * @param sessions текущий массив всех сессий
   * @param index индекс текущей сесси
   * @returns исправленный timePeriod
   */
  static fixTimePeriod(sessions: Array<any>, sessionIndex: number): string {
    const _eventSessionsDurationSum = Math.max(
      sessions[sessionIndex].eventSessionItems.reduce(
        (sum, current) => sum + Number(current.duration),
        0,
      ),
      30,
    );
    const _timePeriodsDifference =
      sessionIndex > 0
        ? this.getTimePeriodsDifference(
            sessions[sessionIndex - 1].timePeriod,
            sessions[sessionIndex].timePeriod,
          )
        : null;

    return _timePeriodsDifference === null
      ? this.incTimePeriod(
          sessions[sessionIndex].timePeriod,
          _eventSessionsDurationSum,
        )
      : this.getNewTimePeriod(
          sessions[sessionIndex - 1].timePeriod,
          _eventSessionsDurationSum,
        );
  }
}
