
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class itemLayout extends Vue {
  @Prop() isOpen!: boolean;

  opened: boolean = true;
  currentChevronIcon: string = mdiChevronUp;
  toggleState() {
    this.opened = !this.opened;
    this.toggleCurrentChevron();
  }
  toggleCurrentChevron() {
    this.currentChevronIcon = this.opened ? mdiChevronUp : mdiChevronDown;
  }
  @Watch("isOpen")
  watchOpenState(value) {
    this.opened = value;
  }
}
